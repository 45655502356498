import React, { useEffect, useState } from 'react';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import {
    Button,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    Box,
    Container,
    IconButton,
} from '@mui/material';
import GeneratForm from './payloadForm';
import logo from '../../images/logo.png';

export function PayloadGenerator({ setSubmitted, status }){
    const [drop, setDrop] = useState(false);
    const [open, setOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () =>  setIsSmallScreen(window.innerWidth < 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };

    return (
        <>
            <Button
                variant='contained'
                color='secondary'
                onClick={handleOpen}
                sx={{ minWidth: 160 }}
            >
                Create Payload
            </Button>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
                transitionDuration={1000}
            >
                <AppBar style={{ backgroundColor: '#151B4D' }}>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <img src={logo} width={40} alt='Logo' />
                        <Typography
                            variant = {isSmallScreen ? "body1" : "h5"}
                            sx={{
                                flex: 1,
                                textAlign: 'center',
                                color: 'white'
                            }}
                        >
                            Generate Payload
                        </Typography>
                        <Box>
                            <IconButton onClick={handleClose} fullWidth color='error' variant="contained">
                                <CancelSharpIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Container style={{ marginTop: isSmallScreen ? 20 : 10 }}>
                    < GeneratForm 
                        setSubmitted={setSubmitted} 
                        setDrop={setDrop} 
                        drop={drop} 
                        BackdropOpen={setOpen}
                    />
                </Container>
            </Dialog>
        </>
    );
}
