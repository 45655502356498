import { useEffect, useState } from "react";
import { 
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography
 } from "@mui/material";
import { GeneralCatchError } from "../utilities/errorHandler";
import axios from "axios";
import { PROXY_ON_PREM_ENDPOINT } from "../auth/origins";
import { Close, PostAdd, RequestQuote } from "@mui/icons-material";
import ShowTable from "./showTable";
import { verifyTIN } from "../api/request";
import React from "react";

/* eslint-disabled */

export default function HealthCheck() {
    const [send, setSend] = useState(false);
    const [openPopUp, closePopUp] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [alert, setAlert] = useState({ message: '', color: `` });
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState({ key: null, TpReference: null });
    const [data, setData] = useState('');

    // Retrieve taxpayer key details from sessionStorage and update state
    useEffect(() => {
        const storedKey = window.sessionStorage.getItem('security_key');
        const storedTpReference = window.sessionStorage.getItem('taxpayer_ref');
        if (storedKey) {
            setKey(prevState => ({ ...prevState, key: storedKey }));
        }
        if (storedTpReference) {
            setKey(prevState => ({ ...prevState, TpReference: storedTpReference }));
        }
    }, []);

     // handle taxpyer key onchange
     const handleKeyChange = (event) => {
        const { name, value } = event.target;
        setKey({ ...key, [name]: value });
    };

    // Submit payload to On Prem VSDC
    const runHealthCheck = async () => {
        window.sessionStorage.setItem('security_key', key.key);
        window.sessionStorage.setItem('taxpayer_ref', key.TpReference);
        setSend(true);
        try {
            const output = await axios.get(`${PROXY_ON_PREM_ENDPOINT}/app/health`, {
                headers: {
                    'Content-Type': 'application/json',
                    'endpoint': `http://localhost:8888/api/v1/taxpayer/${key.TpReference}`,
                    'security_key': key.key
                }
            });
            const response = output.data.status;
            const status = response && response === "UP" ? "UP" : "DOWN";
            setData(status);
            setShowTable(true);
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const { response: { data: { message } } } = error;
                const err = JSON.stringify(message, null, 2);
                setAlert((e) => ({ ...e, message: err, color: 'error' }));
            } else {
                setAlert((e) => ({ ...e, message: "Error making health check", color: 'error', header: "Health Check" }));
            }
            setOpen(true);
        }
        setSend(false);
    }

    const handleOpenUp = () => { closePopUp(true); };
    const handleCloseUp = () => { setAlert((res) => ({...res, color: '', header: '', message: '' })); closePopUp(false); };

    return (
        <>
        <Button
                variant='contained'
                color='info'
                onClick={handleOpenUp}
                sx={{ minWidth: 160 }}
            >
                Health Check
            </Button>
            <Backdrop color='secondary' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={send}>
                <CircularProgress size={35} color="inherit" />
            </Backdrop>
            {alert.message ? <GeneralCatchError alert={alert} handleClose={()=> setOpen(false)} open={open} /> : null}
            <Dialog fullScreen open={openPopUp}>
                <DialogTitle>
                    <Typography textAlign='center' variant='h5' color='darkblue'>Run VSDC Health Check</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} py={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Security Key"
                                    value={key.key || ""}
                                    size='small'
                                    name='key'
                                    onChange={handleKeyChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Taxpayer Reference"
                                    value={key.TpReference || ""}
                                    size='small'
                                    name='TpReference'
                                    placeholder='C000000000X-001'
                                    onChange={handleKeyChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Button
                                    onClick={runHealthCheck}
                                    fullWidth
                                    color='inherit'
                                    variant="contained"
                                    size='medium'
                                    startIcon={<RequestQuote />}
                                >
                                    Request Status
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Button
                                    onClick={handleCloseUp}
                                    fullWidth
                                    color='error'
                                    variant="contained"
                                    size='medium'
                                    startIcon={<Close />}
                                >
                                    Close Page
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {showTable && (
                <Dialog open={showTable}>
                    <DialogTitle>
                        <Typography color='darkred' variant="h6">VSDC Status</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography fontSize={12} textAlign='center' variant="body1">{data}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <IconButton color="error">
                            <Close fontSize="large" onClick={()=>setShowTable(false)}/>
                        </IconButton>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}