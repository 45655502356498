import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer,
    Toolbar,
    List,
    ListItem,
    Typography,
    Divider,
    IconButton,
    AppBar,
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ValidatorPage from './layout/validatorPage';
import TaxpayerTIN from './layout/tinValidator';
import { PayloadGenerator } from './layout/payloadGenerator';
import SandBox from './layout/sandbox';
import ProxyServer from './layout/proxyServer';
import APIButton from './layout/postmanAPI';
import HealthCheck from './layout/runHealthCheck';

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        variants: [{
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            },
        }],
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PayloadValidator() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openDrawer = () => setOpen(true);
    const closeDrawer = () => setOpen(false);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                open={open}
                sx={{ backgroundColor: '#55139a', paddingBottom: isSmallScreen ? 1 : 0 }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={openDrawer}
                        edge="start"
                        sx={[{  mr: 2 }, open && { display: 'none' }]}
                    >
                        <MenuIcon fontSize='large' />
                    </IconButton>
                    <Typography color='#FFFFFF' align='center' fontSize={isSmallScreen ? 20 : 30} sx={{ flexGrow: 1 }}>
                        GRA E-VAT API V8.2 Validator <div style={{ fontSize: 15 }}></div>
                    </Typography>
                    < TaxpayerTIN />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="left"
                open={open}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 2,
                    }
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={closeDrawer}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon fontSize='large' /> : <ChevronRightIcon fontSize='large' />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem key={PayloadGenerator}>
                        < PayloadGenerator />
                    </ListItem>
                    <ListItem key={SandBox}>
                        < SandBox />
                    </ListItem>
                    <ListItem key={HealthCheck}>
                        < HealthCheck />
                    </ListItem>
                    <ListItem key={ProxyServer}>
                        < ProxyServer />
                    </ListItem>
                    <ListItem key={APIButton}>
                        < APIButton />
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open} sx={{ paddingTop: isSmallScreen ? 5 : 2 }}>
                <DrawerHeader />
                <ValidatorPage />
            </Main>
        </Box>
    );
}
