import { Button } from '@mui/material';

export default function APIButton() {
    return (
        <Button 
            variant='contained' 
            color='primary' 
            onClick={() => window.open("https://documenter.getpostman.com/view/29596173/2s9YJgSKXe")}
            sx={{ minWidth: 160 }}
        >
            Test API
        </Button>
    );
}
