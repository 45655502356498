import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    Typography,
    Box,
    Dialog,
    DialogContent,
    Backdrop,
    CircularProgress,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
    Snackbar,
} from '@mui/material';
import { CancelSharp, CloseFullscreen, CopyAll } from '@mui/icons-material';
import { writeText } from 'clipboard-polyfill';

import { GRA_ENDPOINT, GRA_KEY, PROXY_ENDPOINT } from '../auth/origins';
import ShowTable from './showTable';
import { GeneralCatchError } from '../utilities/errorHandler';
import PostToOnprem from './postToOnprem';
import { verifyTIN } from '../api/request';
import logo from '../../images/logo.png';

// /* eslint-disable */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function SandBox() {
    const [userPayload, setUserPayload] = useState([]);
    const [ourPayload, setOurPayload] = useState([]);
    const [data, setData] = useState([]);
    const [copied, setCopied] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [send, setSend] = useState(false);
    const [open, setOpen] = useState(false);
    const [openPopUp, closePopUp] = useState(false);
    const [onprem, setOnprem] = useState(false);
    const [alert, setAlert] = useState({ color: "", message: "", header: "" });

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Check the user userPayload if it is valid and push the data into ourPayload basket
    useEffect(() => {
        if (userPayload) {
            try {
                const parsedLoad = JSON.parse(userPayload);
                setOurPayload(parsedLoad);
            } catch (error) {
                setOurPayload('');
            }
        }
    }, [userPayload]);

    // Copy Formatted userPayload to Clipboard
    const copyToClipboard = (data) => {
        const userPayloadText = JSON.stringify(userPayload, null, 2);
        writeText(userPayloadText)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 3000);
            })
            .catch((error) => {
                window.alert('Failed to copy!')
            });
    };

    const setValue = (e) => { setUserPayload(e.target.value); setOurPayload([]) };

    // Open dialog for on-prem Security Key Form
    const OpenOnprem = () => {
        setOnprem(true);
    }

    // Submit userPayload to GRA backend
    const sendPayloadToGRABackend = async () => {
        try {
            setSend(true);
            const output = await axios.post(`${PROXY_ENDPOINT}/invoice`, ourPayload, {
                headers: {
                    'Content-Type': 'application/json',
                    'endpoint': GRA_ENDPOINT,
                    'security_key': GRA_KEY
                }
            });
            const response = output.data.response;
            const res = await verifyTIN(response.distributor_tin);
            if (res.status === "SUCCESS") {
                setData({ ...response, ...res });
            } else {
                setData(response);
            }
            setShowTable(true);
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const { response: { data: { message } } } = error;
                const err = JSON.stringify(message, null, 2);
                setAlert((e) => ({ ...e, message: err, color: 'error' }));
            } else {
                setAlert((e) => ({ ...e, message: "Network error. Try posting payload again", color: 'error', header: "Request To GRA Failed!" }));
            }
            setOpen(true);
            setData([]);
        }
        setSend(false);
    };
    const handleClose = (event, reason) => { if (reason === 'clickaway') { return; } setOpen(false) };
    const handleOpenUp = () => { closePopUp(true); };
    const handleCloseUp = () => { setAlert((res) => ({...res, color: '', header: '', message: '' })); closePopUp(false); };

    // Return the viewer on the browser
    return (
        <>
            <Button
                variant='contained'
                color='warning'
                onClick={handleOpenUp}
                sx={{ minWidth: 160 }}
            >
                SandBox
            </Button>

            <Backdrop color='secondary' xs={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={send}>
                <CircularProgress size={35} color="inherit" />
            </Backdrop>
            {alert.message ? <GeneralCatchError alert={alert} handleClose={handleClose} open={open} /> : null}

            <Dialog
                fullScreen
                open={openPopUp}
                TransitionComponent={Transition}
                transitionDuration={1000}
            >
            <Box xs={{ width: 500 }}>
                <Snackbar
                    open={copied}
                    message="Copied to clipboard!"
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    color='#1B50CB'
                />
            </Box>
                <AppBar style={{ backgroundColor: '#151B4D' }}>
                    <Toolbar sx={{ justifyContent: 'space-between' }}>
                        <img src={logo} width={40} alt='Logo' />
                        <Typography
                            variant={isSmallScreen ? "body1" : "h5"}
                            sx={{
                                flex: 1,
                                textAlign: 'center',
                                color: 'white'
                            }}
                        >
                            API Playground
                        </Typography>
                        <Box>
                            <IconButton onClick={handleCloseUp} fullWidth color='error' variant="contained">
                                <CancelSharp />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box
                    sx={{
                        paddingTop: isSmallScreen ? 8 : 10,
                        paddingLeft: isSmallScreen ? 2 : 5,
                        paddingRight: isSmallScreen ? 1 : 5,
                    }}
                >
                    <textarea
                        type='text'
                        rows={35}
                        cols={ isSmallScreen ? 45 : 195 }
                        onChange={setValue}
                        placeholder={'API Sandbox - Edit Anything and Submit'}
                    />
                    <Box display='flex' flexDirection='row' gap={2}>
                        <Button
                            fullWidth
                            variant='outlined'
                            color='primary'
                            size='medium'
                            onClick={sendPayloadToGRABackend}
                            title='Send userPayload to GRA'
                        >
                            Post To GRA Cloud
                        </Button>
                        <Button
                            fullWidth
                            variant='outlined'
                            color='secondary'
                            size='medium'
                            onClick={OpenOnprem}
                            title='Send userPayload to GRA'
                        >
                            Post To On-Prem
                        </Button>
                    </Box>
                    <Box display='flex' flexDirection='row' gap={1} paddingTop={1}>
                        <Button
                            fullWidth
                            variant='outlined'
                            color='success'
                            size='medium'
                            onClick={copyToClipboard}
                            title='Copy Payload'
                            startIcon={<CopyAll />}
                        >
                            Copy Payload
                        </Button>
                        <Button
                            fullWidth
                            variant='outlined'
                            color='error'
                            size='medium'
                            onClick={handleCloseUp}
                            startIcon={<CloseFullscreen />}
                        >
                            Close Page
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <PostToOnprem
                openDialog={onprem}
                payload={ourPayload}
                setOpendialog={setOnprem}
                setAlertPop={setAlert}
                setSendOnprem={setSend}
                sendonprem={send}
                openAlert={setOpen}
            />

            {showTable && (
                <Dialog open={showTable}>
                    <DialogContent>
                        <ShowTable
                            setShowTable={setShowTable}
                            response={data}
                            payload={ourPayload}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}
