import { useEffect, useState } from "react";
import { 
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    TextField,
    Typography
 } from "@mui/material";
import { GeneralCatchError } from "../utilities/errorHandler";
import axios from "axios";
import { PROXY_ON_PREM_ENDPOINT } from "../auth/origins";
import { Close, PostAdd } from "@mui/icons-material";
import ShowTable from "./showTable";
import { verifyTIN } from "../api/request";

/* eslint-disabled */

export default function PostToOnprem({ payload, openDialog, setOpendialog, setAlertPop, openAlert, setSendOnprem }) {
    const [send, setSend] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [alert, setAlert] = useState({ message: '', color: `` });
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState({ key: null, TpReference: null });
    const [data, setData] = useState([]);

    // Retrieve taxpayer key details from sessionStorage and update state
    useEffect(() => {
        const storedKey = window.sessionStorage.getItem('security_key');
        const storedTpReference = window.sessionStorage.getItem('taxpayer_ref');
        if (storedKey) {
            setKey(prevState => ({ ...prevState, key: storedKey }));
        }
        if (storedTpReference) {
            setKey(prevState => ({ ...prevState, TpReference: storedTpReference }));
        }
    }, []);

     // handle taxpyer key onchange
     const handleKeyChange = (event) => {
        const { name, value } = event.target;
        setKey({ ...key, [name]: value });
    };

    // Submit payload to On Prem VSDC
    const sendPayloadToOnprem = async () => {
        window.sessionStorage.setItem('security_key', key.key);
        window.sessionStorage.setItem('taxpayer_ref', key.TpReference);
        if (payload.length > 0) {
            const errorMessage = `${payload.join(', ')} cannot be empty.`;
            setAlert((e) => ({ ...e, message: errorMessage, color: 'error' }));
            if(openAlert){openAlert(true)};
            if(setAlertPop){setAlertPop((e) => ({ ...e, message: errorMessage, color: 'error' }))};
            return setOpen(true);
        }
        setOpendialog(false);
        try {
            setSend(true);
            if(setSendOnprem){setSendOnprem(true)}
            const output = await axios.post(`${PROXY_ON_PREM_ENDPOINT}/invoice`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'endpoint': `http://localhost:8888/api/v1/taxpayer/${key.TpReference}`,
                    'security_key': key.key
                }
            });
            const response = output.data.response;
            const res = await verifyTIN(response.distributor_tin);
            if (res.status === "SUCCESS") {
                setData({...response, ...res});
            } else {
                setData(response);
            }
            setOpendialog(false);
            setShowTable(true);
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const { response: { data: { message } } } = error;
                const err = JSON.stringify(message, null, 2);
                setAlert((e) => ({ ...e, message: err, color: 'error' }));
                if(setAlertPop){setAlertPop((e) => ({ ...e, message: err, color: 'error' }))};
            } else {
                if(setAlertPop){setAlertPop((e) => ({ ...e, message: "Error sending payload to GRA", color: 'error', header: "Request To GRA Failed!" }))};
                setAlert((e) => ({ ...e, message: "Error sending payload to GRA", color: 'error', header: "Request To GRA Failed!" }));
            }
            setOpen(true);
            if(openAlert){openAlert(true)};
        }
        setSend(false);
        if(setSendOnprem){setSendOnprem(false)}
    }

    return (
        <>
            <Backdrop color='secondary' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={send}>
                <CircularProgress size={35} color="inherit" />
            </Backdrop>
            {alert.message ? <GeneralCatchError alert={alert} handleClose={()=> setOpen(false)} open={open} /> : null}
            <Dialog open={openDialog}>
                <DialogTitle>
                    <Typography variant='h5' color='darkblue'>GRA VSDC Security KEY</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} py={3}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Security Key"
                                    value={key.key || ""}
                                    size='small'
                                    name='key'
                                    onChange={handleKeyChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Reference"
                                    value={key.TpReference || ""}
                                    size='small'
                                    name='TpReference'
                                    placeholder='C000000000X-001'
                                    onChange={handleKeyChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Button
                                    onClick={sendPayloadToOnprem}
                                    fullWidth
                                    color='secondary'
                                    variant="contained"
                                    size='medium'
                                    startIcon={<PostAdd />}
                                >
                                    Post
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Button
                                    onClick={() => setOpendialog(false)}
                                    fullWidth
                                    color='error'
                                    variant="contained"
                                    size='medium'
                                    startIcon={<Close />}
                                >
                                    Cancel
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {showTable && (
                <Dialog open={showTable}>
                    <DialogContent>
                        <ShowTable
                            setShowTable={setShowTable} 
                            response={data} 
                            payload={payload}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}