/* eslint-disable */
import { Box } from "@mui/material";
import bg from './images/bg.png'
import PayloadValidator from "./components";

function App() {
  return (
    <Box sx={{
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: '98vh'
    }}
   >
      < PayloadValidator />
    </Box>
  );
}

export default App;
