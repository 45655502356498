// Trim Payload
export const trimPayload = (header) => {
    const mandatoryFields = [
        'currency',
        'calculationType',
        'transactionDate',
        'exchangeRate',
        'saleType',
        'businessPartnerName',
        'businessPartnerTin',
        'invoiceNumber',
        'userName',
        'flag',
        'totalAmount',
        'items'
    ];
    return mandatoryFields.filter(field => !header[field] || header[field] === '');
}
